<template>
  <div>
    <!-- <ConnectWallet /> -->
    <div class="z-0">
      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="text-center mt-16">
          <hr class="h-px my-4 border-blue-500 border" />

          <h2
            class="max-w-lg font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto animate-charcter"
          >
            AI NEWS
          </h2>
          <hr class="h-px my-4 border-blue-500 border" />
        </div>

        <div class="grid grid-cols-1 gap-2 lg:grid-cols-3 md:grid-cols-2 mt-10">
          <a
            class="transition duration-300 ease-in-out hover:-translate-y-1 cursor-pointer rounded-sm rounded"
            v-for="s in news"
            :key="s"
            :href="'/news/' + s.list"
          >
            <div
              class="p-1 bg-gradient-to-r from-green-500 via-pink-600 to-blue-500 rounded h-full"
            >
              <div class="border-none rounded bg-gray-900 h-full">
                <img
                  :src="s.img"
                  alt="Image 1"
                  class="block rounded bg-white bg-cover inset-0 object-cover ease-in-out shadow-lg w-full"
                />

                <div class="flex justify-center">
                  <h5 class="p-5 text-xl text-white font-extrabold font-sans">
                    {{ s.title }}
                  </h5>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";

export default {
  data() {
    return {
      news: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657420112053/AI_Replace_Human.png",
          title: "Will Artificial Intelligence (AI) replace humans ?",
          list: "1",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657885683784/AI.png",
          title: "AI in agency industry",
          list: "2",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658401579018/Comparison.png",
          title: "Comparison between virtual influencer and human influencer ?",
          list: "3",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658795847692/What_is_virtualKOL.png",
          title: "What is VirtualKOL ?",
          list: "4",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
  },
  methods: {},
  created() {},
};
</script>
