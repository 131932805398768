<template>
  <div>
    <ConnectWallet />
    <div class="z-0 font-sans" v-if="getToken == 'false'">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://cdn.discordapp.com/attachments/1080058777971212370/1121285658401579018/Comparison.png');
        "
      >
        <div class="text-center">
          <span class="text-5xl font-bold sm:text-8xl text-white">
            Comparison between virtual influencer and human influencer ?
          </span>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="text-center">
          <hr class="h-px my-4 border-blue-700 border" />

          <a
            class="text-xl font-medium xl:text-5xl hover:-translate-y-1 cursor-pointer text-shadow-white text-purple-900"
          >
            Comparison between virtual influencer and human influencer ?
          </a>
          <hr class="h-px my-4 border-blue-700 border" />
        </div>

        <h3 class="text-xl font-medium xl:text-2xl text-purple-900">
          Virtual influencers and human influencers are two distinct types of
          influencers with some key differences. Here's a comparison between the
          two:
        </h3>

        <div class="flex justify-center">
          <ul class="list-disc text-lg xl:text-xl mt-5">
            <li class="mt-2">
              Authenticity: Human influencers are real people with their own
              personalities, experiences, and perspectives, which often adds an
              element of authenticity to their content. Virtual influencers, on
              the other hand, are computer-generated or fictional characters,
              which can make them appear less genuine or relatable to some
              audiences.
            </li>
            <li class="mt-4">
              Creation and Control: Human influencers create content based on
              their own lives, interests, and expertise. They have full control
              over their content creation process, voice, and personal brand.
              Virtual influencers, however, are usually created and managed by a
              team of creators, artists, and marketers. Their content and
              persona are carefully crafted and controlled to fit a specific
              narrative or brand image. rates.
            </li>
            <li class="mt-4">
              Physical Limitations: Human influencers have physical limitations,
              such as time, location, and appearance. They need to be present to
              create content and engage with their audience. Virtual
              influencers, being digital creations, have no such limitations.
              They can be available 24/7, create content remotely, and maintain
              a consistent appearance.
            </li>
            <li class="mt-4">
              Longevity and Scalability: Human influencers' careers are subject
              to factors like aging, changing interests, and personal
              circumstances. Virtual influencers, on the other hand, can
              theoretically exist indefinitely. They can be easily scaled and
              replicated across platforms, allowing for broader reach and
              potential collaborations.
            </li>

            <li class="mt-4">
              Brand Partnerships: Human influencers often collaborate with
              brands and promote products or services that align with their
              personal brand and audience. Virtual influencers can also engage
              in brand partnerships and sponsored content, but their
              endorsements may be perceived differently since their authenticity
              is inherently tied to their artificial nature.
            </li>

            <li class="mt-4">
              Risks and Challenges: Human influencers face risks associated with
              privacy, mental health, and the potential for public scrutiny.
              Virtual influencers, being fictional characters, are less
              vulnerable to these risks. However, they face challenges in terms
              of audience acceptance, skepticism, and the need to constantly
              innovate to stay relevant.
            </li>
          </ul>
        </div>

        <h3 class="text-lg font-bold xl:text-lg mt-5">
          It's important to note that the influencer landscape is constantly
          evolving, and virtual influencers are still relatively new. Both human
          and virtual influencers have their own strengths and appeal to
          different audiences. The choice between the two depends on the brand's
          objectives, target audience, and the type of content and engagement
          they seek.
        </h3>
      </div>

      <News />
      <Footer />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import News from "@/components/News.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      news: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657420112053/AI_Replace_Human.png",
          title: "Will Artificial Intelligence (AI) replace humans ?",
          list: "1",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657885683784/AI.png",
          title: "AI in agency industry",
          list: "2",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658401579018/Comparison.png",
          title: "Comparison between virtual influencer and human influencer ?",
          list: "3",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658795847692/What_is_virtualKOL.png",
          title: "What is VirtualKOL ?",
          list: "4",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    News,
    Footer,
  },
  computed: mapGetters(["getToken"]),

  methods: {},
  created() {},
};
</script>
