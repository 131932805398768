<template>
  <div class="mt-20 bg-opacity-0 font-sans" style="background-color: #27115e">
    <div class="flex flex-col justify-start flex-grow w-full text-gray-200">
      <div
        class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      >
        <!-- <div class="relative flex flex-col items-center">
          <a href="/search" class="mt-1"> Marketplace </a>
          <a href="/funds" class="mt-1"> Account </a>
          <a href="/sell" class="mt-1"> Sell </a>
          <a href="/search" class="mt-1"> Search </a>
          <a
            href="https://promptdue.gitbook.io/promptdue/"
            target="_blank"
            class="mt-1"
          >
            Documents
          </a>
        </div> -->

        <div class="flex flex-col items-center mt-5">
          <a href="/influencerrs" class="m-3 text-xl"> INFLUENCERS </a>

          <div class="flex">
            <a href="/news" class="m-3 text-xl"> AI NEWS </a>
            <a href="/contact" class="m-3 text-xl"> CONTACT US </a>
          </div>
        </div>

        <div class="flex items-center mt-5">
          <div class="flex-grow border-t border-pink-400"></div>
          <span class="flex-shrink mx-4 text-pink-600">Join the community</span>
          <div class="flex-grow border-t border-pink-400"></div>
        </div>

        <div class="flex flex-col items-center p-5">
          <div class="flex">
            <a
              class="m-2 cursor-pointer"
              href="https://twitter.com/virtualkol"
              target="_blank"
            >
              <i class="fab fa-twitter" style="font-size: 40px"></i>
            </a>

            <a
              class="m-2 cursor-pointer"
              href="https://www.reddit.com/user/Virtualkol"
              target="_blank"
            >
              <i class="fab fa-reddit" style="font-size: 40px"></i>
            </a>

            <a
              class="m-2 cursor-pointer"
              href="https://www.youtube.com/channel/UCbEFSdDIIEB5eL7FZ9wX35Q"
              target="_blank"
            >
              <i class="fab fa-youtube" style="font-size: 40px"></i>
            </a>

            <a
              class="m-2 cursor-pointer"
              href="https://www.instagram.com/virtualkol/"
              target="_blank"
            >
              <i class="fab fa-instagram" style="font-size: 40px"></i>
            </a>

            <a
              class="m-2 cursor-pointer"
              href="https://web.facebook.com/virtualkol"
              target="_blank"
            >
              <i class="fab fa-facebook" style="font-size: 40px"></i>
            </a>

            <a
              class="m-2 cursor-pointer"
              href="https://www.tiktok.com/@virtualkol"
              target="_blank"
            >
              <img
                src="https://yt3.googleusercontent.com/7cLwBKEif2gYhhBY25ySrTbj1sLeT62tRAIp02Vi8YI6PnWvssT2aivpKojouMeTuo3MyBE3jUU=s900-c-k-c0x00ffffff-no-rj"
                class="w-10 rounded-3xl"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
